import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Modal,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Tooltip,
    Typography,
    CircularProgress,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AttendanceTable from '../components/AttendanceTable';
import ClockOutModal from '../components/ClockOutModal';
import axios from 'axios';
import { useMessage } from '../layouts/Header';
import { handleAxiosError } from '../utils/function';
import useModal from '../hooks/useModal';
import Loading from '../components/Loading';

const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const Years = Array(41)
    .fill(1)
    .map((_, i) => i + 2010);

const Index = () => {
    const [metrics, setMetrics] = useState(null);
    const { showError, showSuccess } = useMessage();
    const [attendanceData, setAttendanceData] = useState(null);
    const [clockInLoading, setClockInLoading] = useState(false);
    const [clockInValidation, setClockInValidation] = useState(null);
    const [clockOutValidation, setClockOutValidation] = useState(null);
    const { state, closeModal, openModal } = useModal();
    const [query, setQuery] = useState({
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });
    let time = new Date().toLocaleTimeString();
    const [currentTime, setCurrentTime] = useState(time);

    const setTime = () => {
        let time = new Date().toLocaleTimeString();
        setCurrentTime(time);
    };

    setInterval(() => {
        setTime();
    }, 1000);

    const clockIn = async function () {
        setClockInLoading(true);
        try {
            const res = await axios.post('/employee/shift/clock-in');
            const { success, message } = res.data;
            if (success) {
                showSuccess(message);
                AttendanceDataFetch();
            }
        } catch (e) {
            handleAxiosError(e, showError);
        } finally {
            setClockInLoading(false);
        }
    };

    const clockOut = async function () {
        openModal();
    };

    const fetchData = useCallback(async function () {
        setMetrics(null);

        try {
            const res = await axios.get('/employee/shift/');

            const monthData = res.data.monthData;
            monthData.total = Object.values(monthData).reduce((acc, val) => acc + val);

            setMetrics(monthData);
            setClockInValidation(res.data.isClockedIn);
            setClockOutValidation(res.data.isClockedOutYesterday);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const AttendanceDataFetch = useCallback(
        async function () {
            try {
                const res = await axios.get(
                    `/employee/shift/attendance?&month=${query.month}&year=${query.year}&sortBy=clockInTime&direction=-1`
                );
                setAttendanceData(res.data.attendance);
            } catch (e) {
                console.log(e);
            }
        },
        [setAttendanceData, query.month, query.year]
    );

    useEffect(() => {
        AttendanceDataFetch();
    }, [AttendanceDataFetch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        setQuery({ ...query, [name]: value });
    };

    return (
        <>
            <Box sx={{ mt: 3 }}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item xs>
                        <Typography variant='h5'>Attendance</Typography>
                    </Grid>
                    <Grid item display='flex' alignItems='center'>
                        <Box mx={2}>
                            {metrics ? (
                                <Button
                                    onClick={clockIn}
                                    variant='contained'
                                    disabled={clockInLoading || clockInValidation}
                                    endIcon={
                                        clockInLoading && (
                                            <CircularProgress
                                                size='20px'
                                                sx={{ color: 'inherit' }}
                                            />
                                        )
                                    }>
                                    Clock In
                                </Button>
                            ) : (
                                <Skeleton sx={{ mx: 2 }} animation='wave' height={50} width={80} />
                            )}
                        </Box>
                        <Box>
                            <Tooltip title='info' placement='top'>
                                <IconButton disableRipple variant='navIcon' sx={{ mr: 0 }}>
                                    <InfoOutlinedIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* card */}
            <Box mt={5}>
                <Grid container spacing={3}>
                    <Grid item lg={3} xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                textAlign: 'center',
                                minHeight: 200,
                            }}>
                            <CardContent>
                                <Typography variant='h5' component='div'>
                                    {currentTime}
                                </Typography>
                                <Typography sx={{ my: 3 }}>Current Time</Typography>
                            </CardContent>

                            {metrics ? (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button
                                        variant='contained'
                                        onClick={clockIn}
                                        disabled={clockInLoading || clockInValidation}
                                        endIcon={
                                            clockInLoading && (
                                                <CircularProgress
                                                    size='20px'
                                                    sx={{ color: 'inherit' }}
                                                />
                                            )
                                        }>
                                        Clock In{' '}
                                    </Button>

                                    <Button
                                        variant='contained'
                                        onClick={clockOut}
                                        disabled={!clockInValidation}
                                        sx={{
                                            mx: 2,
                                        }}>
                                        Clock Out{' '}
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                    <Skeleton animation='wave' height={70} width={80} />
                                    <Skeleton
                                        sx={{ mx: 2 }}
                                        animation='wave'
                                        height={70}
                                        width={80}
                                    />
                                </Box>
                            )}
                        </Card>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Card sx={{ minHeight: 200, position: 'relative' }} elevation={0}>
                            <CardContent>
                                <Box
                                    sx={{
                                        borderLeftWidth: '5px',
                                        borderLeftColor: 'primary.main',
                                        borderLeftStyle: 'solid',
                                        height: '40px',
                                        width: '5px',
                                        position: 'absolute',

                                        left: 1,
                                    }}></Box>
                                <Typography variant='body1' sx={{ mb: 4 }}>
                                    Days Overview This Month
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            background='#D6E0FF'
                                            color='#4271FF'
                                            name='total'
                                        />

                                        <Typography variant='body2'>Total Days</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            color='#0DCD94'
                                            background='#E6FAF4'
                                            name='Present'
                                        />
                                        <Typography variant='body2'>Present Days</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            color='#F7284A'
                                            background='#FFE9EC'
                                            name='Absent'
                                        />
                                        <Typography variant='body2'>Absent Days</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            color='#E3B113'
                                            background='#FFF9E7'
                                            name='HalfDay'
                                        />
                                        <Typography variant='body2'>Half Days</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            color='#F34932'
                                            background='#FEECEA'
                                            name='Late'
                                        />
                                        <Typography variant='body2'>Late Days</Typography>
                                    </Grid>
                                    <Grid item lg={2} md={6} xs={12} textAlign='center'>
                                        <MetricIcon
                                            metrics={metrics}
                                            color='#EF5CB8'
                                            background='#FEEDF8'
                                            name='Holiday'
                                        />
                                        <Typography variant='body2'>Holidays</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            {/* table */}
            <Box mt={5}>
                <Card sx={{ minHeight: '100%', mb: 5, position: 'relative' }} elevation={0}>
                    <CardContent>
                        <Box
                            sx={{
                                borderLeftWidth: '5px',
                                borderLeftColor: 'primary.main',
                                borderLeftStyle: 'solid',
                                height: '40px',
                                width: '5px',
                                position: 'absolute',

                                left: 1,
                            }}></Box>
                        <Typography variant='h6' sx={{ mb: 4 }}>
                            Attendance Overview
                        </Typography>
                        <Grid container spacing={4} alignItems='end'>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id='demo-simple-select-label'>Month:</InputLabel>
                                    <Select
                                        name='month'
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={query.month}
                                        label='Select Year'
                                        onChange={handleChange}>
                                        {Months.map((month, i) => (
                                            <MenuItem value={i + 1}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id='demo-simple-select-label'>Year:</InputLabel>
                                    <Select
                                        name='year'
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        value={query.year}
                                        label='Year'
                                        onChange={handleChange}>
                                        {Years.map(year => (
                                            <MenuItem value={year}>{year}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {attendanceData ? (
                        <Box mt={5}>
                            <AttendanceTable attendanceData={attendanceData} />
                        </Box>
                    ) : (
                        <Box>
                            <Loading />
                        </Box>
                    )}
                </Card>
            </Box>
            <Modal open={state} onClose={closeModal}>
                <ClockOutModal handleClose={closeModal} AttendanceDataFetch={AttendanceDataFetch} />
            </Modal>
        </>
    );
};

export default Index;

function MetricIcon(props) {
    const { metrics, background, name, color } = props;
    return (
        <IconButton
            size='medium'
            sx={{
                borderRadius: '4px',
                color,
                ...(metrics ? { background } : {}),
                mb: 1,
            }}>
            {metrics ? (
                metrics[name] ? (
                    metrics[name].toString().padStart(2, '0')
                ) : (
                    '00'
                )
            ) : (
                <Skeleton animation='wave' height={30} width={30} />
            )}
        </IconButton>
    );
}
